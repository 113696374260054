<template>
  <div class="main">
    <div class="d-flex">
      <!-- Title -->
      <h1 class="flex-grow-1">
        {{ $t('msg.crossdock.title') }}
      </h1>
      <!-- Actions -->
      <a-button
        type="primary"
        size="large"
        icon="share-alt"
        :loading="processing"
        @click="onConsolidate">
        {{ $t('msg.crossdock.action.consolidate') }}
      </a-button>
    </div>
    <!-- Metrics -->
    <a-row class="my-4" :gutter="8">
      <a-col :xs="12" :sm="12" :lg="6">
        <a-card>
          <a-statistic
            :title="$t('msg.crossdock.metric1')"
            :value="metrics.deliver || 0">
            <template #prefix>
              <span class="fe fe-home"/>
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :xs="12" :sm="12" :lg="6">
        <a-card>
          <a-statistic
            :title="$t('msg.crossdock.metric2')"
            :value="metrics.consolidate || 0">
            <template #prefix>
              <span class="fe fe-layers"/>
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :xs="12" :sm="12" :lg="6">
        <a-card>
          <a-statistic
            :title="$t('msg.crossdock.metric3')"
            :value="metrics.blocked || 0">
            <template #prefix>
              <span class="fe fe-lock"/>
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :xs="12" :sm="12" :lg="6">
        <a-card>
          <a-statistic
            :title="$t('msg.crossdock.metric4')"
            :value="metrics.collected || 0"
            :value-style="{ color: 'crimson' }">
            <template #prefix>
              <span class="fe fe-alert-triangle"/>
            </template>
          </a-statistic>
        </a-card>
      </a-col>
    </a-row>
    <!-- Routes -->
    <div class="card">
      <div class="card-body px-5 py-3">
        <a-list
          size="small"
          :data-source="routes.data">
          <a-list-item
            slot="renderItem"
            slot-scope="item">
            <a-list-item-meta>
              <h5 slot="title" class="py-2">
               {{ $t('route') }} {{ item.code }}
              </h5>
            </a-list-item-meta>
            <div>
              <span class="d-none d-md-inline">{{ $t('msg.crossdock.metric1') }}:</span>
              <span
                role="button"
                class="badge badge-light font-size-18 mx-2"
                @click="() => onFilter(item.code)">
                {{ item.total }}
              </span>
            </div>
          </a-list-item>
        </a-list>
      </div>
    </div>
  </div>
</template>
<script>
import lms from '@/api/lms'
export default {
  data: function () {
    return {
      processing: false,
      pagination: {
        page: 1,
        size: 500,
      },
    }
  },
  computed: {
    metrics() {
      return this.$store.getters['operation/METRICS']
    },
    routes() {
      return this.$store.getters['operation/ROUTES']
    },
  },
  mounted() {
    this.fetchMetrics()
    this.fetchRoutes()
  },
  methods: {
    fetchMetrics() {
      return lms.bff.getMetrics(this.pagination)
        .then(response => this.$store.commit('operation/SET_METRICS', response.data))
    },
    fetchRoutes() {
      return lms.bff.getRoutes(this.pagination)
        .then(response => this.$store.commit('operation/SET_ROUTES', response.data))
    },
    onConsolidate() {
      this.processing = true
      lms.bff.getPickingOrderList(this.$store.state.settings.printer)
        .then(response => window.open(response.data.url, '_blank'))
      lms.bff.getPickingOrderLabels(this.$store.state.settings.printer)
        .then(response => window.open(response.data.url, '_blank'))
        .finally(() => (this.processing = false))
    },
    onFilter(route) {
      const status = 'TO_DELIVER'
      this.$store.commit('shipments/PUT_FILTERS', { route, status })
      this.$router.push('shipments')
    },
  },
}
</script>
